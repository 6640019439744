import authHeader from './auth-header';
import api from './api';

class PurchaseService {

    purchase(data) {
        return api.post('/purchase', data, { headers: authHeader() })
        .then((response) => response.data);
    }
    
    getSession() {
        return api.get('/purchase/session', { headers: authHeader() })
        .then((response) => response.data);
    }

    getSessionDetails(id) {
        return api.get('/purchase/session-details?id=' + id, { headers: authHeader() })
        .then((response) => response.data);
    }
}

export default new PurchaseService();