import { Controller, useForm } from "react-hook-form";
import PopupModal from "../../General/PopupModal/PopupModal";

import "./LoginModal.scss";
import { useModal } from "../../../Contexts/ModalContext";
import RegisterModal from "../RegisterModal/RegisterModal";
import TwoFactorModal from "../TwoFactorModal/TwoFactorModal";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../Actions/auth";
import { useRef, useState } from "react";
import Turnstile, { useTurnstile } from "react-turnstile";
import { useNavigate } from "react-router-dom";
import purchaseService from "../../../Services/purchase.service";
import ForgotModal from "../ForgotModal/ForgotModal";

function LoginModal(props) {
    const turnstile = useTurnstile();
    const turnstileRef = useRef(null);
    const [token, setToken] = useState();

    const { handleSubmit, control, formState: { errors } } = useForm();

    const { openModal, closeModal } = useModal();

    const [isLoading, setLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOpenRegister = () => {
        closeModal();
        openModal(RegisterModal);
    }

    const handleOpenForgot = () => {
        closeModal();
        openModal(ForgotModal);
    }

    const { message } = useSelector(state => state.message);

    const onSubmit = (data) => {
        setLoading(true);

        dispatch(login(data.email, data.password, token))
            .then((verified) => {

                if (!verified) {
                    closeModal();
                    openModal(TwoFactorModal, data);

                    return;
                }

                closeModal();

                if (props.quantity) {
                    purchaseService.purchase({ productId: props.productId, quantity: props.quantity }).then((data) => {
                        window.location.href = data.url;
                    });
                } else {
                    navigate("/dashboard");
                }
            })
            .catch(() => {
                turnstile.reset(turnstileRef.current);
                setLoading(false);
            });
    };

    return (
        <PopupModal>
            <div className="login-modal">
                <div className="modal-header">
                    <h3 className="modal-title">Sign In</h3>
                    <p className="modal-subtitle">Please enter your personal details to continue.</p>
                </div>
                <div className="modal-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-item w-100">
                            <label>Email</label>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^\S+@\S+$/i,
                                        message: 'Invalid email',
                                    },
                                }}
                                render={({ field }) => (
                                    <input type="text" {...field} placeholder="Enter your email address..." />
                                )}
                            />
                            <p className="error">{errors.email?.message}</p>
                        </div>
                        <div className="form-item w-100">
                            <div className="flex-row">
                                <label>Password</label>
                                <a className="forgot" onClick={handleOpenForgot}>Forgot your password?</a>
                            </div>
                            <Controller
                                name="password"
                                control={control}
                                rules={{
                                    required: 'Password is required',
                                    minLength: {
                                        value: 8,
                                        message: 'Password must be at least 8 characters',
                                    },
                                }}
                                render={({ field }) => (
                                    <input type="password" {...field} placeholder="Enter your password..." />
                                )}
                            />
                            <p className="error">{errors.password?.message}</p>
                        </div>
                        <Turnstile userRef={turnstileRef} sitekey={process.env.REACT_APP_TURNSTILE_KEY} onVerify={setToken} />
                        {
                            message && <p className="error">
                                {message}
                            </p>
                        }
                        <button id="submit-form" type="submit" disabled={isLoading || !token}>
                            {isLoading && <div className="loader"></div>}
                            Sign In
                        </button>
                    </form>
                </div>
                <div className="modal-footer">
                    <hr />
                    <p>Don't have an account yet? Get started <a className="text-underline" onClick={handleOpenRegister}>here</a>.</p>
                </div>
            </div>
        </PopupModal>
    )
}

export default LoginModal;